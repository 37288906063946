import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Box, Typography, Button, Modal, Checkbox, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Backdrop,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import moment from "moment";
import ErrorModal from "../../shared-components/ErrorModal";
import SuccessModal from "../../shared-components/SuccessModal";

const OrderRequest = () => {
  const [userID, setUserId] = useState("");
  const [user, setUser] = useState({});
  const [tempCreditPeriod, setTempCreditPeriod] = useState({});
  const [bowserData, setBowserData] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [openPriceListModal, setOpenPriceListModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCreditConfirmModal, setOpenCreditConfirmModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openPricingErrorModal, setOpenPricingErrorModal] = useState(false);
  const [openSelectQuantityError, setSelectQuantityError] = useState(false);
  const [openSelectDeliveryError, setSelectDeliveryError] = useState(false);
  const [openMinimumErrorModal, setMinimumErrorModal] = useState(false);
  const [openBowserQtyErrorModal, setBowserQtyErrorModal] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const [separateItems, setSeparateItems] = useState([]);
  const [fuelItems, setFuelItems] = useState([]);
  const [filteredItems] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [remainingAmount, setTotalUnusedAmount] = useState(0);
  const [Overpayment, setOverPayment] = useState(0);
  const [overpaymentAmount, setOverpaymentAmount] = useState(0);
  const [isFormEnabled, setIsFormEnabled] = useState(true);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchSeparateAPI = async () => {
    const token = localStorage.getItem("token");
    setLoading(true); // Start the loading spinner

    try {
      const [itemsResponse, bowserResponse] = await Promise.all([
        axios.get("https://dealerdemo.unitedpetroleumlanka.com/be/api/item/items", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }),
        axios.get("https://dealerdemo.unitedpetroleumlanka.com/be/api/bowser", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      ]);

      if (itemsResponse.data?.data) {
        const apiItems = itemsResponse.data?.data;

        // const bowserData = await bowserResponse.json();

        // Compare the unit prices between fetched items and existing frontend items
        const priceMismatchItems = apiItems.filter((apiItem) => {
          const matchingItem = fuelItems.find((frontendItem) => frontendItem.no === apiItem.no);

          // Check if matchingItem exists before accessing its properties
          if (matchingItem && matchingItem.unitPrice !== undefined && apiItem.unitPrice !== undefined) {
            return Number(Number(matchingItem.unitPrice).toFixed(2)) !== Number(Number(apiItem.unitPrice).toFixed(2));
          }
          return false; // If no matching item or no unitPrice, don't include in mismatch
        });

        if (priceMismatchItems.length > 0) {
          // Show error message with the item numbers that have mismatched prices
          const itemNumbers = priceMismatchItems.map((item) => item.no).join(", ");
          setOpenPricingErrorModal(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setSeparateItems(apiItems);
        }
      } else {
        console.error("Unexpected response structure:", itemsResponse.data);
      }
    } catch (error) {
      console.error("Error fetching separate API data:", error);
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  // Fetch existing items when the component mounts
  // useEffect(() => {
  //   fetchItems(); // Fetch frontend items
  // }, []);

  // Call fetchSeparateAPI when the window is reloaded
  useEffect(() => {
    window.addEventListener("load", fetchSeparateAPI);
    // Cleanup event listener when the component is unmounted
    return () => {
      window.removeEventListener("load", fetchSeparateAPI);
    };
  }, []);

  //const [selectedDate, setSelectedDate] = useState(deliveryDates[0]);
  const MINIMUM_QUANTITY = 6600;

  const calculateTimeToNextRefresh = (targetHours, targetMinutes) => {
    const now = new Date();
    const nextRefreshTime = new Date();
    nextRefreshTime.setHours(targetHours, targetMinutes, 0, 0);

    if (now >= nextRefreshTime) {
      nextRefreshTime.setDate(nextRefreshTime.getDate() + 1);
    }

    // Return the time difference in milliseconds
    return nextRefreshTime - now;
  };

  const setRefreshTimers = () => {
    const refreshTimes = [
      { hours: 8, minutes: 9 },
      { hours: 12, minutes: 0 },
      { hours: 0, minutes: 0 }
    ];

    refreshTimes.forEach(({ hours, minutes }) => {
      const timeUntilNextRefresh = calculateTimeToNextRefresh(hours, minutes);
      if (timeUntilNextRefresh > 0) {
        setTimeout(() => {
          window.location.reload();
        }, timeUntilNextRefresh);
      }
    });
  };

  const getQuantityOptions = (description) => {
    if (description === "Auto Diesel" || description === "Petrol 92 Octane" || description === "Kerosene") {
      return [6600, 13200, 19800, 26400, 33000];
    }
    return [3300, 6600, 9900, 13200, 16500, 19800, 23100, 26400, 29700, 33000];
  };

  useEffect(() => {
    // Set refresh timers only once when the component mounts
    setRefreshTimers();
  }, []);

  useEffect(() => {
    // const today = new Date();
    // //const dateString = today.toLocaleDateString();
    // setCurrentDate(today);

    const today = moment();
    const dateString = today.format("YYYY-MM-DD");
    setCurrentDate(dateString);
    const storedUserID = localStorage.getItem("userID");
    setUserId(storedUserID);

    if (storedUserID) {
      const savedQuantities = localStorage.getItem(`quantities_${storedUserID}`);
      if (savedQuantities) {
        setQuantities(JSON.parse(savedQuantities));
      }
    }

    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/user/details/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const data = response.data?.data;

        if (data) {
          setUser(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching user details.", error);
      }
    };

    const fetchBowserDetails = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/bowser/bowser/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const data = response.data?.data;

        if (!data) {
          const errorData = await response.text();
          console.error("Error fetching data:", errorData);
          throw new Error("Failed to fetch bowser details.");
        }

        // Ensure that the data is an array before setting the state
        if (data && Array.isArray(data)) {
          setBowserData(data);
        } else {
          console.error("Fetched data is not an array:", data);
          setBowserData([]); // Default to an empty array if the data is not as expected
        }
      } catch (error) {
        console.error("An error occurred while fetching bowser details:", error);
      }
    };

    const fetchItems = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }
      try {
        const response = await axios.get("https://dealerdemo.unitedpetroleumlanka.com/be/api/item/items/all", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data?.data && response.data?.data.length) {
          const limitedItems = response.data?.data.slice(0, 10).map((item) => {
            const endingDate = new Date(item.endingDate);

            // Check current date
            if (currentDate > new Date(endingDate.setHours(0, 0, 0, 0))) {
              // If past the ending date, set price to zero
              return { ...item, unitPrice: 0 };
            }

            return item; // Return the item as is if not expired
          });

          setFuelItems(limitedItems);

          const firstItem = limitedItems[0];
          if (firstItem) {
            setStartingDate(firstItem.startingDate);
            setEndingDate(firstItem.endingDate);
          } else {
            console.error("First item not found in the response:", limitedItems);
          }
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    const fetchTransactions = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      // if (!token || !userID) {
      //   window.location.href = "/login";
      //   return;
      // }

      setUserId(userID);

      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/order/transactions/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // const userTransactions = response.data.transactions.flatMap(
        //   (transactionDoc) => transactionDoc.transactions.filter(
        //     (transaction) => transaction.reference === userID
        //   )
        // );
        setTransactions(response.data?.data.transactions);
        setTotalUnusedAmount(response.data?.data.remainingAmount);
        setOverpaymentAmount(response.data?.data.overpaymentAmount);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    const fetchTempCreditPeriod = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/order/tempCreditPeriod`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const activePeriods = response.data.data;

        if (activePeriods && activePeriods.length > 0) {
          setTempCreditPeriod(activePeriods[0]);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn("No active temporary credit periods found.");
        } else {
          console.error("An error occurred while fetching.", error);
        }
      }
    };

    fetchBowserDetails();
    //handleRefresh();
    fetchUserDetails();
    fetchItems();
    fetchTransactions();
    fetchSeparateAPI();
    fetchTempCreditPeriod();

    //window.location.reload(fetchSeparateAPI());
  }, [userID]);

  // const handleRefresh = () => {
  //   fetchItems(); // Refresh main API
  //   fetchSeparateAPI(); // Refresh separate API
  // };
  useEffect(() => {
    // This will be called when the component is mounted or window is reloaded
    window.addEventListener("load", fetchSeparateAPI);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("load", fetchSeparateAPI);
    };
  }, []);

  useEffect(() => {
    if (userID) {
      localStorage.setItem(`quantities_${userID}`, JSON.stringify(quantities));
    }
  }, [quantities, userID]);

  const handleQuantityChange = (no, value) => {
    setQuantities((prevQuantities) => {
      const item = fuelItems.find((item) => item.no === no);
      const unitPrice = item ? parseFloat(item.unitPrice) : 0;

      // If the unit price is 0, reset the quantity to "-"
      if (unitPrice === 0) {
        return { ...prevQuantities, [no]: "" }; // Set to "-" in the Select dropdown
      }

      return { ...prevQuantities, [no]: value };
    });
  };
  const formatPrice = (price) => {
    const number = parseFloat(price);
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const formatQuantity = (quantity) => {
    const number = parseFloat(quantity);
    return number.toLocaleString(undefined);
  };

  const calculateTotalPrice = () => {
    // Calculate total price excluding items with zero total price
    return fuelItems
      .filter(({ no, unitPrice }) => {
        const quantity = quantities[no];
        const numericQuantity = quantity === "-" ? 0 : parseFloat(quantity) || 0;
        return parseFloat(unitPrice) * numericQuantity > 0;
      })
      .reduce((total, { no, unitPrice }) => {
        const quantity = quantities[no];
        const numericQuantity = quantity === "-" ? 0 : parseFloat(quantity) || 0;
        return total + parseFloat(unitPrice) * numericQuantity;
      }, 0)
      .toFixed(2);
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const getDeliveryDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    let startDate = new Date(currentDate);

    // After 10:30 AM, show delivery starting from the next day
    startDate.setDate(startDate.getDate() + 1);

    const deliveryDates = [];
    let daysToShow = 3;

    // Custom logic for specific dates
    if (currentDate.getFullYear() === 2024 && currentDate.getMonth() === 8) {
      if (currentDay === 15) {
        daysToShow = 3;
      } else if (currentDay === 16) {
        daysToShow = 2;
      } else if (currentDay === 17) {
        daysToShow = 1;
      }
    }

    let daysAdded = 0;

    while (daysAdded < daysToShow) {
      if (startDate.getDay() !== 0) {
        deliveryDates.push(formatDate(startDate));
        daysAdded++;
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    return deliveryDates;
  };

  const deliveryDates = getDeliveryDates();
  const [selectedDate, setSelectedDate] = useState(deliveryDates[0]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const getDeliveryMethod = () => {
    const deliveryMethod = ["-", "Delivery", "Own Pickup"];
    return deliveryMethod;
  };

  const deliveryMethod = getDeliveryMethod();
  const [selectedMethod, setSelectedMethod] = useState(deliveryMethod[0]);
  const [pickupMethod, setpickupMethod] = useState("");
  const [openPickupModal, setOpenPickupModal] = useState(false);

  const handleMethodChange = (event) => {
    const method = event.target.value;
    setSelectedMethod(method);

    // Show the modal if Pickup is selected
    if (method === "Own Pickup") {
      setOpenPickupModal(true);
    }
  };

  const handlePickupOptionChange = (value) => {
    setpickupMethod(value);
  };
  const handlePickupDialogClose = () => {
    setOpenPickupModal(false);
  };

  const handleConfirmPickup = () => {
    if (!pickupMethod) {
      setSelectDeliveryError(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setOpenPickupModal(false);
  };

  const handlePriceListPopup = () => {
    setOpenPriceListModal(true);
  };

  const handleClosePriceListModal = () => {
    setOpenPriceListModal(false);
  };

  const checkMinimumQuantity = () => {
    const totalQuantity = Object.values(quantities).reduce((sum, qty) => {
      const parsedQty = parseFloat(qty);
      return sum + (isNaN(parsedQty) ? 0 : parsedQty);
    }, 0);

    return totalQuantity >= MINIMUM_QUANTITY;
  };

  const getBowserQuantity = (pickupMethod) => {
    const bowser = bowserData.find((b) => b.code.includes(pickupMethod));
    return bowser ? bowser.capacity : 0;
  };

  useEffect(() => {
    if (!user.enableTransportMethod) {
      setSelectedMethod("Delivery");
    }
  }, [user.enableTransportMethod]);

  const handleSubmit = () => {
    const totalAmount = parseFloat(calculateTotalPrice());
    const amountPaid = Number(Number(remainingAmount).toFixed(2));
    const creditAmount = Number(Number(user.availableCreditLCY).toFixed(2));
    const tempCredit = tempCreditPeriod?.isActive ? Number(Number(user.temporaryCredit).toFixed(2)) : 0;
    const totalQuantity = Object.values(quantities).reduce((sum, qty) => {
      const parsedQty = parseFloat(qty);
      return sum + (isNaN(parsedQty) ? 0 : parsedQty);
    }, 0);

    // Get the selected bowser's quantity based on the pickupMethod
    let selectedBowserQuantity = 0;
    if (selectedMethod === "Own Pickup" && pickupMethod) {
      selectedBowserQuantity = getBowserQuantity(pickupMethod);
    }

    // Perform validations
    if (!checkMinimumQuantity()) {
      setMinimumErrorModal(true);
    } else if (totalAmount === 0) {
      setSelectQuantityError(true);
    } else if (selectedMethod === "-") {
      setSelectDeliveryError(true);
    } else if (selectedMethod === "Own Pickup" && !pickupMethod) {
      setSelectDeliveryError(true);
    } else if (selectedMethod === "Own Pickup" && totalQuantity > selectedBowserQuantity) {
      setBowserQtyErrorModal(true); // Show error if total quantity exceeds the selected bowser quantity
    } else if (amountPaid < totalAmount) {
      const totalCreditAvailable = creditAmount + tempCredit;

      if (
        totalCreditAvailable + amountPaid >= totalAmount ||
        totalCreditAvailable >= totalAmount || // If total available credit is enough
        creditAmount >= totalAmount || // Or individual credits are enough
        tempCredit >= totalAmount

        // Check if total available credit is less than the total amount
      ) {
        setOpenCreditConfirmModal(true); // Open credit confirmation modal
        fetchSeparateAPI();

        // Show error if credit is insufficient
      } else if (totalCreditAvailable < totalAmount || totalCreditAvailable < totalAmount) {
        setOpenErrorModal(true);
      } else {
        setOpenErrorModal(true); // Default error case
      }
    } else {
      setOpenConfirmModal(true);
      fetchSeparateAPI();
    }
  };

  const placeCreditOrder = async (creditOrder) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post("https://dealerdemo.unitedpetroleumlanka.com/be/api/order/creditOrder", creditOrder, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 201) {
        setQuantities({});
        localStorage.removeItem(`quantities_${userID}`);
        setOpenSuccessModal(true);
        //setLoading(true);
        setTimeout(() => {
          window.location.reload();
          //setLoading(false);
        }, 1000);
      } else {
        console.error("Failed to place credit order:", response.data);
      }
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.error("Error placing credit order:", error.response ? error.response.data : error.message);
    }
  };

  const handleCreditOrder = async () => {
    setLoading(true);

    setTimeout(() => {
      const filteredItems = fuelItems
        .filter(({ no }) => {
          const quantity = quantities[no];

          const numericQuantity = quantity === "-" ? 0 : parseFloat(quantity) || 0;

          // Find the corresponding item from fuelItems to get its unit price
          const item = fuelItems.find((item) => item.no === no);

          // If the item is not found or unitPrice is invalid, skip the item
          if (!item || isNaN(item.unitPrice)) return false;

          const unitPrice = parseFloat(item.unitPrice);

          // Filter items based on valid quantity and unit price
          return numericQuantity > 0 && unitPrice > 0;
        })
        .map(({ no, description, unitPrice }) => ({
          itemCode: no,
          itemName: description,
          quantity: parseFloat(quantities[no]) || 0, // Ensure quantity is a number
          unitPrice: parseFloat(unitPrice)
        }));
      const creditOrder = {
        userID: userID,
        items: filteredItems,
        totalAmount: parseFloat(calculateTotalPrice()),
        deliveryDate: selectedDate,
        deliveryMethod: selectedMethod,
        bowserNo: selectedMethod === "Own Pickup" ? pickupMethod : " "
      };

      placeCreditOrder(creditOrder);
      setLoading(false);
      setOpenCreditConfirmModal(false);
    }, 3000);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setOpenCreditConfirmModal(false);
  };

  const navigate = useNavigate();

  const placeOrder = async (order) => {
    //setLoading(true);
    //setLoading(true);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post("https://dealerdemo.unitedpetroleumlanka.com/be/api/order/placeOrder", order, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 201) {
        setQuantities({});
        localStorage.removeItem(`quantities_${userID}`);

        setOpenSuccessModal(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Failed to place order:", response.data);
      }
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.error("Error sending order to external API:", error.response ? error.response.data : error.message);
    }
  };

  const handleConfirm = () => {
    const totalAmount = parseFloat(calculateTotalPrice());
    const amountPaid = parseFloat(remainingAmount.toFixed(2));
    const overpayment = amountPaid > totalAmount ? amountPaid - totalAmount : 0;
    setOverPayment(overpayment);
    setLoading(true);

    setTimeout(() => {
      const filteredItems = fuelItems
        .filter(({ no }) => {
          const quantity = quantities[no];

          const numericQuantity = quantity === "-" ? 0 : parseFloat(quantity) || 0;

          const item = fuelItems.find((item) => item.no === no);

          if (!item || isNaN(item.unitPrice)) return false;

          const unitPrice = parseFloat(item.unitPrice);

          return numericQuantity > 0 && unitPrice > 0;
        })
        .map(({ no, description, unitPrice }) => ({
          itemCode: no,
          itemName: description,
          quantity: parseFloat(quantities[no]) || 0
        }));
      const order = {
        userID: userID,
        items: filteredItems,
        totalAmount: parseFloat(calculateTotalPrice()),
        amountPaid: remainingAmount.toFixed(2),
        transactions: transactions.map((transaction) => ({
          transactionId: transaction.transactionId,
          account: transaction.account,
          type: transaction.type,
          amount: transaction.amount
        })),
        overpayment: overpayment,
        deliveryDate: selectedDate,
        deliveryMethod: selectedMethod,
        bowserNo: selectedMethod === "Own Pickup" ? pickupMethod : " "
      };

      placeOrder(order);
      setLoading(false);
      setOpenConfirmModal(false);
    }, 3000);
  };

  const handleViewDetails = () => {
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setMinimumErrorModal(false);
    setSelectQuantityError(false);
    setSelectDeliveryError(false);
    setOpenPricingErrorModal(false);
    setBowserQtyErrorModal(false);
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const checkFormAvailability = () => {
    const now = new Date();
    const hours = now.getHours();

    // if (hours >= 16 || hours < 0) {
    //   setIsFormEnabled(false);
    // } else {
    setIsFormEnabled(true);
    // }
  };

  useEffect(() => {
    checkFormAvailability();

    const intervalId = setInterval(checkFormAvailability, 60000);

    return () => clearInterval(intervalId);
  }, [userID]);

  return (
    <Container
      sx={{
        backgroundColor: "#FFFFFF",
        minHeight: "100vh",
        marginTop: 14,
        pt: 4,
        fontFamily: "Poppins, sans-serif"
      }}
    >
      {/* Order Date */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start"
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            variant: "h6",
            textAlign: "left"
          }}
        >
          Date: {formatDate(currentDate)}
        </Typography>
      </Box>
      <Paper
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 2,
          backgroundColor: "#FFFFFF",
          fontFamily: "Poppins, sans-serif"
        }}
      >
        {/* Delivery Date */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Stack items vertically
            alignItems: "flex-start", // Align items to the start of the container
            gap: 1 // Add some space between the label and the dropdown
          }}
        >
          {/* Delivery Date Label */}
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              variant: "h6",
              textAlign: "right"
            }}
          >
            Delivery Date:
          </Typography>

          {/* Delivery Date Dropdown */}
          <Select
            value={selectedDate}
            onChange={handleDateChange}
            sx={{ minWidth: 150 }} // Adjust width as needed
          >
            {deliveryDates.map((date, index) => (
              <MenuItem key={index} value={date}>
                {date}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {user.enableTransportMethod ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 1
            }}
          >
            {/* Delivery Method Label */}
            <Typography
              sx={{
                fontFamily: "Poppins, sans-serif",
                variant: "h6",
                textAlign: "right"
              }}
            >
              Delivery Method:
            </Typography>

            {/* Delivery Method Dropdown */}
            <Select value={selectedMethod} onChange={handleMethodChange} sx={{ minWidth: 150 }}>
              {deliveryMethod.map((method, index) => (
                <MenuItem key={index} value={method}>
                  {method}
                </MenuItem>
              ))}
            </Select>

            {/* Pickup Modal */}
            <Modal open={openPickupModal} onClose={handlePickupDialogClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxWidth: "600px",
                  borderRadius: 2,
                  backgroundColor: "#10153F",
                  color: "#FFFFFF",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflow: "hidden"
                }}
              >
                <IconButton
                  onClick={handlePickupDialogClose}
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    color: "#FFFFFF",
                    zIndex: 1000
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    textAlign: "center",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#10153F",
                    zIndex: 10,
                    p: 2
                  }}
                >
                  Bowser No.
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    height: "calc(100% - 32px)",
                    overflowY: "auto"
                  }}
                >
                  <FormGroup>
                  {Array.isArray(bowserData) &&
    bowserData.length > 0 &&
    bowserData.map(({ code, capacity }, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Radio
                                sx={{ color: "#FFFFFF" }}
                                checked={pickupMethod === code} // Only one option selected
                                onChange={() => handlePickupOptionChange(code)} // Handle selection
                              />
                            }
                            label={`${code} - (Capacity: ${formatQuantity(capacity)})`}
                          />
                        ))
                      }
                  </FormGroup>

                  <DialogActions sx={{ justifyContent: "center", mt: 3 }}>
                    <Button
                      onClick={handlePickupDialogClose}
                      variant="outlined"
                      sx={{
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleConfirmPickup}
                      variant="outlined"
                      sx={{
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                  <Box
                    sx={{
                      mt: 5,
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
        ) : (
          // Render this when enableTransportMethod is false
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 1
            }}
          >
            {/* Delivery Method Label */}
            <Typography
              sx={{
                fontFamily: "Poppins, sans-serif",
                variant: "h6",
                textAlign: "right"
              }}
            >
              Delivery Method:
            </Typography>

            {/* Box Styled Like a Dropdown */}
            <Box
              sx={{
                minWidth: 150,
                minHeight: 37,
                padding: "8px",
                border: "1px solid #cccccc",
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: "center", // Centers content horizontally
                alignItems: "center", // Centers content vertically
                borderRadius: "4px"
              }}
            >
              Delivery
            </Box>
          </Box>
        )}
      </Paper>

      {/* Item ordering table*/}
      <TableContainer sx={{ backgroundColor: "#CEEDFF", fontFamily: "Poppins, sans-serif" }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  border: "1px solid",
                  borderColor: "#FFFFFF",
                  backgroundColor: "#10153F",
                  color: "#FFFFFF",
                  justifyContent: "center"
                }}
              >
                Code
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  border: "1px solid",
                  borderColor: "#FFFFFF",
                  backgroundColor: "#10153F",
                  color: "#FFFFFF",
                  justifyContent: "center"
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  border: "1px solid",
                  borderColor: "#FFFFFF",
                  backgroundColor: "#10153F",
                  color: "#FFFFFF",
                  justifyContent: "center"
                }}
              >
                Unit Price
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  border: "1px solid",
                  borderColor: "#FFFFFF",
                  backgroundColor: "#10153F",
                  color: "#FFFFFF",
                  justifyContent: "center"
                }}
              >
                Quantity (L)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelItems.map(({ no, description, unitPrice }) => (
              <TableRow key={no} sx={{ mb: 2 }}>
                <TableCell
                  sx={{
                    py: 2,
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: 1,
                    marginLeft: 1,
                    fontWeight: "bold",
                    border: "1px solid",
                    borderColor: "#FFFFFF"
                  }}
                >
                  {no}
                </TableCell>
                <TableCell
                  sx={{
                    py: 2,
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: 1,
                    marginLeft: 4,
                    border: "1px solid",
                    borderColor: "#FFFFFF"
                  }}
                >
                  {capitalizeFirstLetter(description)}
                </TableCell>
                <TableCell
                  sx={{
                    py: 2,
                    fontFamily: "Poppins, sans-serif",
                    border: "1px solid",
                    borderColor: "#FFFFFF"
                  }}
                >
                  {formatPrice(unitPrice)}
                </TableCell>
                <TableCell
                  sx={{
                    py: 2,
                    fontFamily: "Poppins, sans-serif",
                    border: "1px solid",
                    borderColor: "#FFFFFF",
                    backgroundColor: "inherit"
                  }}
                >
                  <Select
                    sx={{
                      backgroundColor: "white",
                      width: 98,
                      zIndex: 1
                    }}
                    value={quantities[no] || ""}
                    onChange={(e) => handleQuantityChange(no, e.target.value)}
                    displayEmpty
                    disabled={parseFloat(unitPrice) === 0}
                  >
                    <MenuItem value="" sx={{ backgroundColor: "white" }}>
                      <em>-</em>
                    </MenuItem>
                    {getQuantityOptions(description).map((num) => (
                      <MenuItem key={num} value={num}>
                        {num.toLocaleString()}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={4}
                align="center"
                sx={{
                  fontSize: "1.25rem",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "white",
                  marginTop: 5,
                  zIndex: 2,
                  position: "relative"
                }}
              >
                Total Price: Rs.{formatPrice(calculateTotalPrice())}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Paper
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "left",
          marginTop: 2,
          backgroundColor: "#CEEDFF",
          fontFamily: "Poppins, sans-serif",
          position: "relative"
        }}
      >
        {/* <Box>
          <Typography fontFamily="Poppins, sans-serif" variant="h6">
            Amount Paid
          </Typography>
          <Typography fontFamily="Poppins, sans-serif" variant="body1">
            Rs.{" "}
            {transactions
              .reduce((total, t) => total + parseFloat(t.amount), 0)
              .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </Typography>
        </Box> */}

        <Box>
          <Typography fontFamily="Poppins, sans-serif" variant="h6">
            Cash Balance
          </Typography>
          <Typography fontFamily="Poppins, sans-serif" variant="body1">
            Rs.{" "}
            {remainingAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Typography>
        </Box>

        <Button
          sx={{
            borderRadius: "20px",
            mr: 2,
            textTransform: "none",
            fontFamily: "Poppins, sans-serif"
          }}
          fontFamily="Poppins, sans-serif"
          variant="contained"
          color="primary"
          onClick={handleViewDetails}
        >
          View Details
        </Button>
        {/* <IconButton
    color="primary"
    //onClick={handleRefresh}
    disabled={loading} 
    sx={{
      position: "absolute", 
      right: 1, 
      top: 5, //from top
      fontSize: "2rem",
      fontWeight: "bold"
    }}
  >
    <RefreshIcon fontSize="inherit"/>
  </IconButton> */}
      </Paper>

      {user && user.creditLimitLCY > 0 && (
        <Paper
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            marginTop: 2,
            backgroundColor: "#CEEDFF",
            fontFamily: "Poppins, sans-serif"
          }}
        >
          <Box>
            <Typography fontFamily="Poppins, sans-serif" variant="h6">
              Available Credit
            </Typography>
            <Typography fontFamily="Poppins, sans-serif" variant="body1">
              Rs. {formatPrice(user.availableCreditLCY)}
            </Typography>
          </Box>

          {/* Only show Credit Limit if creditLimitLCY > 0 */}

          <Box>
            <Typography fontFamily="Poppins, sans-serif" variant="h6">
              Credit Limit
            </Typography>
            <Typography fontFamily="Poppins, sans-serif" variant="body1">
              Rs. {formatPrice(user.creditLimitLCY)}
            </Typography>
          </Box>
        </Paper>
      )}

      {tempCreditPeriod?.isActive && user.temporaryCredit > 0 && user?.hasTempCredit &&(
        <Paper
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            marginTop: 2,
            backgroundColor: "#CEEDFF",
            fontFamily: "Poppins, sans-serif"
          }}
        >
          <Box>
            <Typography fontFamily="Poppins, sans-serif" variant="h6">
              Credit
            </Typography>
            <Typography fontFamily="Poppins, sans-serif" variant="body1">
              Rs. {formatPrice(user.temporaryCredit)}
            </Typography>
          </Box>
          <Box>
            <Box>
              <Typography fontFamily="Poppins, sans-serif" variant="body1">
                Credit valid from:
              </Typography>
              <Typography fontFamily="Poppins, sans-serif" variant="body2" sx={{ fontWeight: "bold" }}>
                {formatDate(tempCreditPeriod?.from)} to {formatDate(tempCreditPeriod?.to)}
              </Typography>
            </Box>
          </Box>
        </Paper>
      )}
      <Paper
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 2,
          backgroundColor: "#FFFFFF",
          fontFamily: "Poppins, sans-serif"
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePriceListPopup}
          sx={{
            mt: 3,
            mb: 2,
            fontFamily: "Poppins, sans-serif",
            padding: "10px 12px",
            fontSize: "1.25rem",
            backgroundColor: "#EAF7FF",
            minWidth: "120px",
            textTransform: "none",
            border: "1px solid",
            boxShadow: "0px 4px 6px rgba(0, 0, 1, 0.1)"
          }}
        >
          Price List
        </Button>
        <Button
          sx={{
            mt: 3,
            mb: 2,
            mr: 2,
            padding: "10px 12px",
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.25rem",
            minWidth: "100px",
            backgroundColor: "#29465B",
            textTransform: "none",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#1f3544"
            }
          }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormEnabled}
        >
          Submit
        </Button>
      </Paper>
      {/* {!isFormEnabled && (
        <Typography
          sx={{
            textAlign: "center",
            color: "red",
            mt: 2,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          The order request form is closed. Please try again tomorrow.
        </Typography>
      )} */}

      {/* View Price List Modal */}
      <Modal open={openPriceListModal} onClose={handleClosePriceListModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "600px",
            borderRadius: 2,
            backgroundColor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflow: "hidden"
          }}
        >
          <IconButton
            onClick={handleClosePriceListModal}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "#FFFFFF",
              zIndex: 1000
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              position: "sticky",
              top: 0,
              backgroundColor: "#10153F", // Ensure background color matches modal
              zIndex: 10,
              p: 2
            }}
          >
            Price List (Rs.)
          </Typography>
          <Box
            sx={{
              position: "relative",
              height: "calc(100% - 32px)",
              overflowY: "auto"
            }}
          >
            <Table
              sx={{
                minWidth: 100,
                width: "auto"
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                      textAlign: "center",
                      justifyContent: "center"
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                      textAlign: "center",
                      justifyContent: "center"
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                      textAlign: "center",
                      justifyContent: "center"
                    }}
                  >
                    Retail Price
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                      textAlign: "center",
                      justifyContent: "center"
                    }}
                  >
                    Unit Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fuelItems.map(({ no, description, retailPrice, unitPrice }) => (
                  <TableRow key={no}>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "center"
                      }}
                    >
                      {no}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "center"
                      }}
                    >
                      {capitalizeFirstLetter(description)}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none",
                        textAlign: "center"
                        //textAlign: "right",
                      }}
                    >
                      {parseFloat(retailPrice).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none",

                        textAlign: "right"
                      }}
                    >
                      {parseFloat(unitPrice).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Typography
            //variant="body 3"
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#FF0000",
              mb: 2,
              fontWeight: "bold",
              marginTop: "40px",
              textAlign: "center"
            }}
          >
            Prices are applicable from <br />
            {formatDate(startingDate)} to {formatDate(endingDate)}
          </Typography>
          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Box>
      </Modal>

      {/* Confirm Modal */}
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            borderRadius: 2,
            backgroundColor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton
            onClick={handleCloseConfirmModal}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "#FFFFFF"
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              marginBottom: 5
            }}
          >
            Confirm Order
          </Typography>
          <Box sx={{ mt: 3 }}>
            {/* <Typography
        variant="h6"
        sx={{ color: "#FFFFFF", fontFamily: "Poppins, sans-serif", mb: 2 }}
      >
        Selected Items
      </Typography> */}
            <Table sx={{ minWidth: 300, marginBottom: 5 }}>
              <TableBody>
                {fuelItems
                  .filter(({ no, unitPrice }) => {
                    const quantity = quantities[no] || 0;
                    return parseFloat(unitPrice) * quantity > 0;
                  })
                  .map(({ no, description }) => {
                    const quantity = quantities[no] || 0;
                    if (quantity > 0) {
                      return (
                        <TableRow key={no}>
                          <TableCell
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins, sans-serif",
                              border: "none"
                            }}
                          >
                            {no}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins, sans-serif",
                              border: "none"
                            }}
                          >
                            {capitalizeFirstLetter(description)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins, sans-serif",
                              border: "none",
                              textAlign: "right"
                            }}
                          >
                            {quantity}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
              </TableBody>
            </Table>
          </Box>
          <Typography sx={{ textAlign: "center" }}>Delivery Date : {selectedDate}</Typography>
          <br />
          <Typography sx={{ textAlign: "center" }}>Delivery Method: {selectedMethod}</Typography>
          {selectedMethod === "Own Pickup" && pickupMethod.length > 0 && <Typography sx={{ textAlign: "center" }}>Bowser: {pickupMethod}</Typography>}
          <br />
          <Typography sx={{ textAlign: "center" }}>Total Price: Rs.{formatPrice(calculateTotalPrice())}</Typography>

          {/* Add the section for displaying selected items and quantities */}
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#FF0000",
              mb: 2,
              fontWeight: "bold",
              marginTop: "40px",
              textAlign: "center"
            }}
          >
            Orders can't be changed or cancelled after it's being placed.
          </Typography>

          <Box sx={{ textAlign: "center", fontFamily: "Poppins, sans-serif" }}>
            <Button
              onClick={handleConfirm}
              sx={{
                color: "#FFFFFF",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "none",
                mt: 5,
                textTransform: "none",
                border: 1,
                position: "relative" // Ensure button's position is relative for proper overlay
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "#FFFFFF" }} /> // Spinner
              ) : (
                "Confirm" // Button label
              )}
            </Button>

            {/* Full-Screen Loader */}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Box>
      </Modal>

      {/* credit orders */}
      {/* Confirm Modal */}
      <Modal open={openCreditConfirmModal} onClose={handleCloseConfirmModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            borderRadius: 2,
            backgroundColor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton
            onClick={handleCloseConfirmModal}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "#FFFFFF"
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              marginBottom: 5
            }}
          >
            Confirm Order
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#FFFFFF",
                fontFamily: "Poppins, sans-serif",
                mb: 2
              }}
            >
              Selected Items
            </Typography>
            <Table sx={{ minWidth: 300, marginBottom: 5 }}>
              <TableBody>
                {fuelItems
                  .filter(({ no, unitPrice }) => {
                    const quantity = quantities[no] || 0;
                    return parseFloat(unitPrice) * quantity > 0;
                  })
                  .map(({ no, description }) => {
                    const quantity = quantities[no] || 0;
                    if (quantity > 0) {
                      return (
                        <TableRow key={no}>
                          <TableCell
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins, sans-serif",
                              border: "none"
                            }}
                          >
                            {no}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins, sans-serif",
                              border: "none"
                            }}
                          >
                            {capitalizeFirstLetter(description)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins, sans-serif",
                              border: "none",
                              textAlign: "right"
                            }}
                          >
                            {quantity}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
              </TableBody>
            </Table>
          </Box>
          <Typography sx={{ textAlign: "center" }}>Delivery Date : {selectedDate}</Typography>
          <br />
          <Typography sx={{ textAlign: "center" }}>Delivery Method: {selectedMethod}</Typography>
          {selectedMethod === "Own Pickup" && pickupMethod && <Typography sx={{ textAlign: "center" }}>Bowser: {pickupMethod}</Typography>}
          <br />
          <Typography sx={{ textAlign: "center" }}>Total Price: Rs.{formatPrice(calculateTotalPrice())}</Typography>

          {/* Add the section for displaying selected items and quantities*/}
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#FF0000",
              mb: 2,
              fontWeight: "bold",
              marginTop: "40px",
              textAlign: "center"
            }}
          >
            Order will be placed using credit.
          </Typography>

          <Box
            sx={{
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <Button
              onClick={handleCreditOrder}
              sx={{
                color: "#FFFFFF",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "none",
                mt: 5,
                textTransform: "none",
                border: 1,
                position: "relative"
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: "#FFFFFF" }} /> : "Confirm credit order"}
            </Button>

            {/* Full-Screen Loader */}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Box>
      </Modal>

      <SuccessModal
        open={openSuccessModal}
        onClose={handleCloseConfirmModal}
        message="Order Placed Successfully!"
        icon={<CheckCircleIcon color="success" sx={{ fontSize: 60 }} />}
        messageColor="success"
      />

      {/* Success Modal
      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            borderRadius: 2,
            backgroundColor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography
              variant="h6"
              color="success"
              sx={{ textAlign: "center" }}
            >
              Order Placed Successfully!
            </Typography>
          </Box>
        </Paper>
      </Modal> */}

      {/* View Details Modal */}
      <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            borderRadius: 2,
            backgroundColor: "#10153F",
            //bgcolor: "background.paper",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto" // Enable vertical scrolling
          }}
        >
          <Typography
            variant="h6"
            fontFamily="Poppins, sans-serif"
            textAlign="center"
            sx={{
              marginBottom: 5
            }}
          >
            Transaction Details
          </Typography>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto"
            }}
          >
            <IconButton
              onClick={handleCloseDetailsModal}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                color: "#FFFFFF"
              }}
            >
              <CloseIcon />
            </IconButton>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif",
                      border: "none",
                      fontWeight: "bold"
                    }}
                  >
                    Currency
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif",
                      border: "none",
                      fontWeight: "bold"
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif",
                      border: "none",
                      fontWeight: "bold"
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif",
                      border: "none",
                      fontWeight: "bold"
                    }}
                  >
                    Account Number
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif",
                      border: "none",
                      fontWeight: "bold"
                    }}
                  >
                    Bank Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.transactionId}>
                    {/* <TableCell>{transaction.transactionId}</TableCell> */}
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none"
                      }}
                    >
                      {transaction.currency}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none"
                      }}
                    >
                      {formatPrice(transaction.amount)}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none"
                      }}
                    >
                      {(() => {
                        const date = new Date(transaction.date);
                        const day = date.getDate().toString().padStart(2, "0");
                        const month = (date.getMonth() + 1).toString().padStart(2, "0");
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      })()}
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none"
                      }}
                    >
                      {transaction.reference}
                    </TableCell> */}
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none"
                      }}
                    >
                      {transaction.account}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        border: "none"
                      }}
                    >
                      {transaction.type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Box>
      </Modal>

      {/* MOQ error modal */}
      <ErrorModal open={openMinimumErrorModal} onClose={handleCloseErrorModal} title="Error" message="The minimum order quantity should be 6,600 liters." />

      {/* price change error modal */}
      <ErrorModal open={openPricingErrorModal} onClose={handleCloseErrorModal} title="Error" message="Prices have changed. Please refresh page." />

      {/* Cash balance error modal */}
      <ErrorModal open={openErrorModal} onClose={handleCloseErrorModal} title="Error" message="Please make sure the cash balance is sufficient before confirming the order." />

      {/* Delivery method error modal */}
      <ErrorModal open={openSelectDeliveryError} onClose={handleCloseErrorModal} title="Error" message="Please select a valid delivery method before confirming the order." />

      {/* Quantity selection error modal */}
      <ErrorModal open={openSelectQuantityError} onClose={handleCloseErrorModal} title="Error" message="Please make sure to select a valid quantity." />

      {/* Bowser qty exceeded */}
      <ErrorModal open={openBowserQtyErrorModal} onClose={handleCloseErrorModal} title="Error" message="Please ensure the order quantity does not exceed the bowser's capacity." />
    </Container>
  );
};

export default OrderRequest;
